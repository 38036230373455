
<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :modal-append-to-body="true"
    append-to-body
    :close-on-click-modal="false"
    style="text-align: left"
    width="80%"
    @close="closeDialog"
  >
    <div class="user-panel" v-loading="loading">
      <el-form
        ref="form"
        :model="formModel"
        :rules="ruleValidate"
        :label-width="'150px'"
        inline
      >
        <el-form-item label="设备编号" prop="deviceNo">
          <el-input
            v-model="formModel.deviceNo"
            placeholder="请输入设备编号"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="设备别名" prop="aliasName">
          <el-input
            v-model="formModel.aliasName"
            placeholder="请输入设备别名"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="绑定人员" prop="personId">
          <el-input
            v-model="formModel.personId"
            placeholder="请输入绑定人员编号"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="紧急联系人号码1" prop="sos1">
          <el-input
            v-model="formModel.sos1"
            placeholder="请输入紧急联系人号码"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="紧急联系人号码2" prop="sos2">
          <el-input
            v-model="formModel.sos2"
            placeholder="请输入紧急联系人号码"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="紧急联系人号码3" prop="sos3">
          <el-input
            v-model="formModel.sos3"
            placeholder="请输入紧急联系人号码"
            style="width: 300px"
          ></el-input>
        </el-form-item> -->
        <el-form-item label="计步器开关" prop="stepSwitch">
          <div style="width:300px;">
           <el-switch
            v-model="formModel.stepSwitch"
            active-color="#13ce66"
            active-text="开"
            inactive-color="#ff4949"
            inactive-text="关"
          ></el-switch>
          </div>
        </el-form-item>
        <el-form-item label="定位间隔时间" prop="locationTimeInterval">
          <el-input
            v-model="formModel.locationTimeInterval"
            placeholder="请输入定位间隔时间"
            style="width: 300px"
          >
          <template slot="append">秒</template>
          </el-input>
        </el-form-item>
        <el-form-item label="心率间隔时间" prop="heartRateTimeInterval">
          <el-input
            v-model="formModel.heartRateTimeInterval"
            placeholder="请输入心率间隔时间"
            style="width: 300px"
          >
          <template slot="append">秒</template>
          </el-input>
        </el-form-item>
        <el-form-item label="血压间隔时间" prop="bpi">
          <el-input
            v-model="formModel.bpi"
            placeholder="请输入血压间隔时间"
            style="width: 300px"
          >
            <template slot="append">秒</template>
          </el-input>
        </el-form-item>
        <el-form-item label="测温间隔时间" prop="frequency">
          <el-input
            v-model="formModel.frequency"
            placeholder="请输入测温间隔时间"
            style="width: 300px"
          >
          <template slot="append">秒</template>
          </el-input>
        </el-form-item>
        <el-form-item label="服务号码" prop="num1">
          <el-input
            v-model="formModel.num1"
            placeholder="请输入服务号码"
            style="width: 300px"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitting"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import baseWatchInfoApi from "@/api/base/watchInfo";

export default {
  props: ["businessKey", "title"],
  data() {
    return {
      ruleValidate: {
        deviceNo: [
          { required: true, message: "设备编号不能为空", trigger: "blur" },
        ],
        aliasName: [
          { required: true, message: "设备别名不能为空", trigger: "blur" },
        ],
        personId: [
          { required: true, message: "绑定人员不能为空", trigger: "blur" },
        ]
      },
      showDialog: true,
      loading: false,
      submitting: false,
      formModel: {}
    };
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;

      this.$refs["form"].validate((valid) => {
        if (valid) {
          (function () {
            var id = self.formModel.id;

            if (id == null || id.length == 0) {
              return baseWatchInfoApi.add(self.formModel);
            } else {
              return baseWatchInfoApi.update(self.formModel);
            }
          })().then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success",
              });

              self.$emit("close", true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning",
              });

              self.$emit("close", false);
            }
          });
        }
      });
    },
  },
  mounted: function () {
    var self = this;

    (function () {
      if (self.businessKey.length == 0) {
        return baseWatchInfoApi.create();
      } else {
        return baseWatchInfoApi.edit(self.businessKey);
      }
    })()
      .then((response) => {
        var jsonData = response.data;
        self.loading = false;

        if (jsonData.result) {
          self.formModel = jsonData.data;
        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch((error) => {
        self.$message.error(error + "");
      });
  },
};
</script>