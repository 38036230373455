import request from '@/utils/request'
import constant from '@/constant'

function pageList(formData){
  return request.post(constant.serverUrl + "/base/watchInfo/pageList", formData);
}

function create(){
  return request.get(constant.serverUrl + "/base/watchInfo/create");
}

function edit(id){
  return request.get(constant.serverUrl + "/base/watchInfo/edit/" + id);
}

function add(formModel){
  return request.post(constant.serverUrl + "/base/watchInfo/add", formModel,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function update(formModel){  
  return request.post(constant.serverUrl + "/base/watchInfo/update", formModel,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function remove(id){
  return request.post(constant.serverUrl + "/base/watchInfo/delete/" + id);
}

function batchRemove(idList){
  return request.post(constant.serverUrl + "/base/watchInfo/batchDelete",idList,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function remoteConfig(id){
  return request.get(constant.serverUrl + "/base/watchInfo/remoteConfig/" + id);
}


export default {
  pageList,create,edit,add,update,remove,batchRemove,remoteConfig
}